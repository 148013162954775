<template>
  <div>
    <div style="margin-top: 140px">
      <section v-if="setInfo != null">
        <v-container>
          <strong> {{ setInfo.name }}</strong>
          <v-divider></v-divider>
        </v-container>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <p v-for="t in setInfo.text" :key="t.key">
                {{ t }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-img :src="setInfo.picture"> </v-img>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section v-else>UPS NO ENCONTRAMOS RESUTLADOS</section>

      <div class="py-12"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapState(["memberText"]),
    setInfo() {
      let member = this.$route.params.id;
      let matchMember;
      for (const i in this.memberText) {
        console.log("Buscando " + member + ">>" + this.memberText[i].member);
        if (this.memberText[i].member == member) {
          matchMember = this.memberText[i];
          console.log(this.memberText[i]);
          return matchMember;
        } else {
          matchMember = null;
        }
      }
      return matchMember;
    },
  },
};
</script>
